import React, {useEffect} from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType} from '../helpers/helper.properties'
import {getCover} from '../helpers/helper.rendering'

import { connect } from "react-redux"

import Loading from '../components/Loading'


const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Propiedad/main")
)

//Components
import About from '../components/Propiedad/about'
import Expand from '../components/Propiedad/expand'

const IndexPage = () => {

  const isSSR = typeof window === "undefined"
  
  return (
    <>
      <Layout>
            <Seo 
                // title={getType(property) + " en "  + getOperations(property)[0] + " - " + getFakeAddres(property)}
                // meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(property) + " en " + getOperations(property)[0] +  " en " +  getLocation(property)} 
                // description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(property) + " en " + getOperations(property)[0] +  " en " +  getLocation(property)} 
                // image={getCover(property.photos)?.original}
            />
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy />
              </React.Suspense>
            )}
            <About />
            <Expand />
      </Layout>
    </>
  )
}

// export default connect(state => ({
//     loading: state.properties.loading,
//     property: state.properties.property,
// }),null)(IndexPage);

export default IndexPage
